/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import partnersTableData from "layouts/partners/data/partnersTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { Backdrop, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon } from "@mui/material";
import SoftButton from "components/SoftButton";
// import partnersTableData from "./data/partnersTableData";
import { useNavigate } from "react-router-dom";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { useEffect, useState } from "react";
import courtena from "api/courtena";
import SoftBadge from "components/SoftBadge";
import SoftAlert from "components/SoftAlert";

function Settlements() {
  // const { columns, rows } = partnersTableData;
  // const { columns: prCols, rows: prRows } = projectsTableData;
  const [settlementData,setSettlementData] = useState()
  const [unsettledBookings,setUnsettledBookings] = useState()
  const [settledBookings,setSettledBookings] = useState()
  const [backdrop,setBackdrop] = useState(false)
  const [settlementId,setSettlementId] = useState()
  const [settlementAlert,setSettlementAlert] = useState(false)
  const [totalPayments,setTotalPayments] = useState()
  const [totalPaymentsWC,setTotalPaymentsWC] = useState()
  const [totalAmountSettled,setTotalAmountSettled] = useState()
  const [totalAmountSettledWC,setTotalAmountSettledWC] = useState()
  const [totalAmountToSettle,setTotalAmountToSettle] = useState()
  const [totalAmountToSettleWC,setTotalAmountToSettleWC] = useState()
  let navigate = useNavigate();

  const partnersTableData = {
    columns: [
      { name: "partner", align: "center" },
      { name: "amount_settled", align: "center" },
      { name: "amount_settled_after_commission", align: "center" },
      { name: "amount_to_settle", align: "center" },
      { name: "amount_to_settle_after_commission", align: "center" },
      { name: "bookings_to_settle", align: "center" },
      { name: "settled_bookings", align: "center" },
      { name: "action", align: "center" },
      // { name: "venue", align: "center" }
    ],
  };
  const { columns } = partnersTableData;

  const getPartnerSettlements = async() => {
    var adminInfoString = localStorage.getItem("admin")
    var adminInfo = JSON.parse(adminInfoString)
    setBackdrop(true)
    await courtena.get("/admin/get-admin-settlements/"+adminInfo._id,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': adminInfo.token
    }
    }).then((res) => {
      setBackdrop(false)
      console.log(res.data.result)
      if(res.data.success){
        let bookingsToSettle = []
          res.data.result.settlements.map((item) => {
            bookingsToSettle.push({
              partner:((
                <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                  <SoftBox display="flex" flexDirection="column">
                    <SoftTypography variant="button" fontWeight="medium">
                      {item.partner.username}
                    </SoftTypography>
                    <SoftTypography variant="caption" color="secondary">
                      {item.partner.email}
                    </SoftTypography>
                    <SoftTypography variant="caption" color="secondary">
                      {item.partner.phone}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              )),
              amount_settled:(<Chip label={item.amountSettled/100 + " SAR"}/>),
              amount_settled_after_commission:(<Chip label={(item.amountSettled - ((item.partnerCommission/100)*item.amountSettled))/100 + " SAR"}/>),
              amount_to_settle:(<Chip label={item.amountToSettle/100 + " SAR"}/>),
              amount_to_settle_after_commission:(<Chip label={(item.amountToSettle - ((item.partnerCommission/100)*item.amountToSettle))/100 + " SAR"}/>),
              bookings_to_settle:(<Chip label={item.bookingsToSettle.length }/>),
              settled_bookings:(<Chip label={item.settledBookings.length}/>),
              action:(<SoftBadge onClick={() => {
                setSettlementAlert(true)
                setSettlementId(item._id)
              }}  badgeContent="Settle"/>)
          })
          })
          setUnsettledBookings(bookingsToSettle)
          setSettlementData(res.data.result.settlements)
          setTotalAmountSettled(res.data.result.totalAmountSettled)
          setTotalAmountSettledWC(res.data.result.totalAmountSettledWithCommission)
          setTotalAmountToSettle(res.data.result.totalAmountToSettle)
          setTotalAmountToSettleWC(res.data.result.totalAmountToSettleWithCommission)
          setTotalPayments(res.data.result.totalPayments)
          setTotalPaymentsWC(res.data.result.totalPaymentsWithCommission)
      }else{

      }
    }).catch(err => {
      setBackdrop(false)
      console.log(err)
    })
  }

  const settlePayment = async() => {
    var adminInfoString = localStorage.getItem("admin")
    var adminInfo = JSON.parse(adminInfoString)
    setBackdrop(true)
    await courtena.get("/admin/settle-payment/"+settlementId,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
        'Authorization': adminInfo.token
    }
    }).then((res) => {
      console.log(res.data)
      if(res.data.success){
        getPartnerSettlements()
        setSettlementAlert(false)
      }
    }).catch((err) => {
      console.log(err)
    })

  }
  useEffect(() => {
    getPartnerSettlements()
  },[])


  return (
    <DashboardLayout>
      <DashboardNavbar light={false} />
      <Dialog open={settlementAlert}>
        <DialogTitle>
          Alert
        </DialogTitle>
        <DialogContent>
          Are you sure you settled this payment?
        </DialogContent>
        <DialogActions>
          <SoftButton onClick={settlePayment} color="info">Yes</SoftButton>
          <SoftButton onClick={() => setSettlementAlert(false)} color="error">Cancel</SoftButton>

        </DialogActions>
      </Dialog>
      <SoftBox py={3} >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} xl={3} mb={3}>
          <MiniStatisticsCard
          title={{ text: "Total Payments" }}
          count={`SAR ${totalPayments?(totalPayments/100):0}`}
          // percentage={{ color: "success", text: "+55%" }}
          icon={{ color: "secondary", component: "paid" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={3} mb={3}>
          <MiniStatisticsCard
          title={{ text: "Total Payments With Commission" }}
          count={`SAR ${totalPaymentsWC?(totalPaymentsWC/100):0}`}
          // percentage={{ color: "success", text: "+55%" }}
          icon={{ color: "secondary", component: "paid" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={3} mb={3}>
          <MiniStatisticsCard
          title={{ text: "Settled Payments" }}
          count={`SAR ${totalAmountSettled ?totalAmountSettled/100:0}`}
          // percentage={{ color: "success", text: "+55%" }}
          icon={{ color: "secondary", component: "paid" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={3} mb={3}>
          <MiniStatisticsCard
          title={{ text: "Settled Payments With Commission" }}
          count={`SAR ${totalAmountSettledWC ?totalAmountSettledWC/100:0}`}
          // percentage={{ color: "success", text: "+55%" }}
          icon={{ color: "secondary", component: "paid" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={3} mb={3}>
          <MiniStatisticsCard
          title={{ text: "Unsettled Payments" }}
          count={`SAR ${totalAmountToSettle ?totalAmountToSettle/100:0}`}
          // percentage={{ color: "success", text: "+55%" }}
          icon={{ color: "secondary", component: "paid" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={3} mb={3}>
          <MiniStatisticsCard
          title={{ text: "Unsettled Payments With Commission" }}
          count={`SAR ${totalAmountToSettleWC ?totalAmountToSettleWC/100:0}`}
          // percentage={{ color: "success", text: "+55%" }}
          icon={{ color: "secondary", component: "paid" }}
          />
        </Grid>
        </Grid>
        <SoftBox mb={3}>
          <Card> 
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <Grid item xs={6} md={6}>
                    <SoftTypography variant="h6">Settlements</SoftTypography>
                </Grid>
              {/* <Grid item xs={6} md={6}>
                <SoftButton onClick={() => navigate("/partners/add-partners")} variant="gradient" color="dark">
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;Add Partner
                </SoftButton>
                </Grid> */}
            </SoftBox>

            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={unsettledBookings} />
            </SoftBox>
          </Card>
        </SoftBox>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}>
        <CircularProgress color="inherit" />
        </Backdrop>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Settlements;
