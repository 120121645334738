// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    scrollbar-width: thin;
    scrollbar-color: #2c3856;
}

/* Targtes on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #a5d9f3;
    border-radius: 5px;
    border: 1px solid #a5d9f3;
}

.cursor-pointer{
    cursor:pointer;
}`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA,wCAAwC;AACxC;IACI,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB","sourcesContent":["* {\n    scrollbar-width: thin;\n    scrollbar-color: #2c3856;\n}\n\n/* Targtes on Chrome, Edge, and Safari */\n*::-webkit-scrollbar {\n    width: 8px;\n}\n\n*::-webkit-scrollbar-track {\n    background: #ffffff;\n}\n\n*::-webkit-scrollbar-thumb {\n    background-color: #a5d9f3;\n    border-radius: 5px;\n    border: 1px solid #a5d9f3;\n}\n\n.cursor-pointer{\n    cursor:pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
