/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
// import partnersTableData from "layouts/tables/data/partnersTableData";

import { Backdrop, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, Icon, InputLabel, MenuItem, Select, Switch,  } from "@mui/material";
import SoftButton from "components/SoftButton";

import { useLocation, useNavigate } from "react-router-dom";
import SoftInput from "components/SoftInput";
import { useEffect, useState } from "react";
import { Dropzone, FileMosaic} from "@dropzone-ui/react"
import courtena from "api/courtena";
// import OpeningHours from "./components/OpeningHours";
import "../../global.css"
import { ArrowDropDown } from "@mui/icons-material";
function CourtOverview() {
  const [data,setData] = useState()
  const [backdrop,setBackdrop] = useState(false)
  let navigate = useNavigate();
  let location = useLocation()

  const getCourtDetails = async() => {
    var adminInfoString = localStorage.getItem("admin")
    var adminInfo = JSON.parse(adminInfoString)

    setBackdrop(true)
    if(location.state.approved && location.state.inReview){
        await courtena.get("/admin/get-edit-in-review-court-details/"+location.state.courtId,{
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': '*/*',
              'Authorization': adminInfo.token
          }
          }).then((response) => {
            console.log(response.data)
            setData(response.data.result.editedFields)
            if(response.data.success){
              setBackdrop(false)
              setSuccess(true)
              setSuccessMessage(response.data.message)
            }else{
              setBackdrop(false)
              setError(true)
              setErrorMessage(response.data.message)
            }
            
          }).catch(err => console.log(err.message));
    }else{
        await courtena.get("/admin/get-in-review-court-details/"+location.state.courtId,{
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': '*/*',
              'Authorization': adminInfo.token
          }
          }).then((response) => {
            console.log(response.data)
            setData(response.data.result)
            if(response.data.success){
              setBackdrop(false)
              setSuccess(true)
              setSuccessMessage(response.data.message)
            }else{
              setBackdrop(false)
              setError(true)
              setErrorMessage(response.data.message)
            }
            
          }).catch(err => console.log(err.message));
    }

    
  }

  const handleSubmit = async() => {
    var adminInfoString = localStorage.getItem("admin")
    var adminInfo = JSON.parse(adminInfoString)
    const data = {courtId:location.state.courtId}
    setBackdrop(true)
    if(location.state.approved && location.state.inReview){
        // alert("edit approve clicked")
        await courtena.post("/admin/approve-edit-court-review/",{data},{
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': '*/*',
              'Authorization': adminInfo.token
          }
          }).then((response) => {
            console.log(response.data)
            setData(response.data.result)
            if(response.data.success){
              setBackdrop(false)
              setSuccess(true)
              setSuccessMessage(response.data.message)
            }else{
              setBackdrop(false)
              setError(true)
              setErrorMessage(response.data.message)
            }
            
          }).catch(err => console.log(err.message));
    }else{
        // alert("approve clicked")
        await courtena.post("/admin/approve-first-time-court-review/",{data},{
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': '*/*',
              'Authorization': adminInfo.token
          }
          }).then((response) => {
            console.log(response.data)
            setData(response.data.result)
            if(response.data.success){
              setBackdrop(false)
              setSuccess(true)
              setSuccessMessage(response.data.message)
            }else{
              setBackdrop(false)
              setError(true)
              setErrorMessage(response.data.message)
            }
            
          }).catch(err => console.log(err.message));
    }
    
  }


  const handleRejectSubmit = async() => {
    var adminInfoString = localStorage.getItem("admin")
    var adminInfo = JSON.parse(adminInfoString)
    const data = {courtId:location.state.courtId}
    setBackdrop(true)
    if(location.state.approved && location.state.inReview){
        // alert("edit approve clicked")
        await courtena.post("/admin/reject-edit-court-review/",{data},{
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': '*/*',
              'Authorization': adminInfo.token
          }
          }).then((response) => {
            console.log(response.data)
            setData(response.data.result)
            if(response.data.success){
              setBackdrop(false)
              setSuccess(true)
              setSuccessMessage(response.data.message)
            }else{
              setBackdrop(false)
              setError(true)
              setErrorMessage(response.data.message)
            }
            
          }).catch(err => console.log(err.message));
    }else{
        // alert("approve clicked")
        await courtena.post("/admin/reject-first-time-court-review/",{data},{
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': '*/*',
              'Authorization': adminInfo.token
          }
          }).then((response) => {
            console.log(response.data)
            setData(response.data.result)
            if(response.data.success){
              setBackdrop(false)
              setSuccess(true)
              setSuccessMessage(response.data.message)
            }else{
              setBackdrop(false)
              setError(true)
              setErrorMessage(response.data.message)
            }
            
          }).catch(err => console.log(err.message));
    }
    
  }


  useEffect(() => {
    
    getCourtDetails()
    
  },[])


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card> 
            <SoftBox p={3} mb={1} textAlign="center">
              <SoftTypography variant="h5" fontWeight="medium">
                Overview
              </SoftTypography>
            </SoftBox>
  
            <SoftBox pt={2} pb={3} px={3}>
              <SoftBox component="form" role="form">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} xl={4}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" fontWeight="bold">Court Name</SoftTypography>
                      <SoftTypography variant="body2">{data?.title}</SoftTypography>
                    </SoftBox>
                  </Grid>
                  
                  <Grid item xs={12} md={6} xl={4}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" fontWeight="bold">Description</SoftTypography>
                      <SoftTypography variant="body2">{data?.description}</SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={6} xl={4}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" fontWeight="bold">Court Type</SoftTypography>
                      <SoftTypography variant="body2">{data?.courtType}</SoftTypography>
                    </SoftBox>
                  </Grid>
                  
                  <Grid item xs={12} md={6} xl={4}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" fontWeight="bold">Approved</SoftTypography>
                      <SoftTypography variant="body2">{data?.approved ? 'Yes' : 'No'}</SoftTypography>
                    </SoftBox>
                  </Grid>
                  {/* Add more fields based on the JSON data */}
                </Grid>

                <SoftBox mt={4}>
              <SoftTypography variant="h5" fontWeight="medium" textAlign="center">
                Court Picture
              </SoftTypography>
              <SoftBox mt={2}>
                {/* {data?.photos?.map((photo, index) => ( */}
                  <img src={data?.image} alt={`Court Photo`} style={{ width: '100%', marginBottom: '10px' }} />
                {/* ))} */}
              </SoftBox>
            </SoftBox>
  
                <SoftBox mt={4} mb={1}>
                  <SoftButton onClick={() => handleSubmit()} variant="gradient" color="dark" fullWidth>
                    Approve
                  </SoftButton>
                </SoftBox>
                <SoftBox mt={4} mb={1}>
                  <SoftButton onClick={() => handleRejectSubmit()} variant="gradient" color="error" fullWidth>
                   Reject
                  </SoftButton>
                </SoftBox>
              </SoftBox>
  
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdrop}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
  
}

export default CourtOverview;
